import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Typography,
  Link,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  Box,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import axiosRetry from "axios-retry";
import PropTypes from "prop-types";
import { useState, useRef, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { getChoices } from "../choices";
import getValidationSchema from "../getValidationSchema";
import GenesysPlatformService from "../services/GenesysPlatformService";
import SurveyQuestion from "./SurveyQuestion";
import SurveyAccordion from "./SurveyAccordion";
import SurveyZipCode from "./SurveyZipCode";
import ChatDialogueIcon from "./icons/ChatDialogueIcon";
import { replaceMainSiteLinkHostname } from "../services/Utils";
import theme from "../themes/theme";
import { serializeSurvey } from "../services/Utils";
import * as Sentry from "@sentry/react";
import SurveyPhoneNumberQuestion from "./SurveyPhoneNumberQuestion";
import useRecaptcha from "../hooks/useRecaptcha";

const SurveyForm = ({ interactionIdKey, filteredParams, gtag }) => {
  const { t, i18n } = useTranslation();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const { thoughtsOfSuicideChoices, upsetScaleChoices } = getChoices(t);
  const [platformServiceConfig, setPlatformServiceConfig] = useState(null);
  const isEnglish = i18n.language.includes("en");
  const validationSchema = getValidationSchema(t);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const startTimeRef = useRef(Date.now());
  const completedFieldsRef = useRef([]);
  const [formStarted, setFormStarted] = useState(false);
  const { executeRecaptcha } = useRecaptcha();
  const [retryCount, setRetryCount] = useState(0);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    clearErrors,
    watch,
    setError,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      modality: "web",
      zipCode: "",
      thoughtsOfSuicide: "",
      upsetAmount: "",
      isLGBTQ: false,
      name: "",
      age: "",
      gender: "",
      gender_unlisted: "",
      transgender: "",
      mainConcerns: [],
      mainConcerns_unlisted: "",
      phoneNumber: "",
    },
  });

  const zipCodeRef = useRef(null);
  const thoughtsOfSuicideRef = useRef(null);
  const upsetAmountRef = useRef(null);

  const scrollToFirstError = useCallback(() => {
    const offset = isDesktop ? 150 : 200;

    const scrollWithOffset = (ref) => {
      const elementPosition = ref.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    };

    if (errors.zipCode) {
      scrollWithOffset(zipCodeRef);
    } else if (errors.thoughtsOfSuicide) {
      scrollWithOffset(thoughtsOfSuicideRef);
    } else if (errors.upsetAmount) {
      scrollWithOffset(upsetAmountRef);
    }
  }, [errors, isDesktop]);

  axiosRetry(axios, {
    retries: 5,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (error) => {
      return (
        axiosRetry.isNetworkError(error) ||
        axiosRetry.isRetryableError(error) ||
        (error.response &&
          error.response.status >= 500 &&
          error.response.status < 600)
      );
    },
  });

  axios.interceptors.request.use((config) => {
    if (config["axios-retry"] && config["axios-retry"].retryCount > 0) {
      setRetryCount(config["axios-retry"].retryCount);
    }
    return config;
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleOnSubmit = async (data) => {
    clearErrors();
    setRetryCount(0);

    const endTime = Date.now();
    const duration = (endTime - startTimeRef.current) / 1000;

    if (gtag) {
      gtag("event", "form_submission", {
        event_category: "988 Lifeline Survey",
        event_label: "Pre-Chat Survey",
        duration: duration,
        completed_fields_count: completedFieldsRef.current.length,
        completed_fields_list: completedFieldsRef.current.join(", "),
      });
    }

    const recaptchaToken = await executeRecaptcha("submit");

    setIsFormSubmitted(true);

    // Filter out unnecessary fields ending with "_unlisted" if their value is undefined
    let cleanedData = {};
    for (const [key, value] of Object.entries(data)) {
      if (!(key.endsWith("_unlisted") && value === undefined)) {
        cleanedData[key] = value;
      }
    }
    cleanedData = {
      ...cleanedData,
      ...filteredParams,
      recaptchaToken,
      interactionIdKey,
      modality: "web",
      locale: normalizeLanguage(i18n.language),
    };

    const defaultName = isEnglish ? "Anonymous" : "Anónimo";

    if (!cleanedData.name) {
      cleanedData.name = defaultName;
    }

    const PostapiEndpoint = import.meta.env.VITE_LIFELINE_CHAT_SURVEY_API_URL;

    const serializedData = serializeSurvey(cleanedData);

    try {
      const surveyResponse = await axios.post(PostapiEndpoint, serializedData, {
        timeout: 30000,
      });

      if (surveyResponse.status === 201) {
        const redirectUrl = surveyResponse?.data?.redirect_url;

        const isThirdParty = redirectUrl?.startsWith("http");

        if (isThirdParty) {
          window.location.href = redirectUrl;
          return;
        }

        serializedData.client_ip = surveyResponse.data.user_ip;
        serializedData.isp = surveyResponse.data.user_isp;
        serializedData.chat_session_id = surveyResponse.data.chat_session_id;
        serializedData.submit_timestamp = surveyResponse.data.submit_timestamp;

        if (
          !serializedData.client_ip ||
          !serializedData.isp ||
          !serializedData.chat_session_id ||
          !serializedData.submit_timestamp
        ) {
          throw new Error("Missing required data from server response.");
        }

        setPlatformServiceConfig({ data: serializedData });
      } else {
        throw new Error(`Unexpected response status: ${surveyResponse.status}`);
      }
    } catch (error) {
      Sentry.captureException(error);
      setIsFormSubmitted(false);

      setRetryCount(0);

      setError("root.serverError", {
        type: "manual",
        message: t("serverError.message"),
      });
      scrollToTop();
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError();
    }
  }, [errors, scrollToFirstError]);

  useEffect(() => {
    if (!formStarted) {
      const handleInteraction = () => {
        if (gtag) {
          gtag("event", "form_start", {
            event_category: "988 Lifeline Survey",
            event_label: "Pre-Chat Survey",
          });
        }
        setFormStarted(true);
        removeEventListeners();
      };

      const formFields = document.querySelectorAll("input, select, textarea");
      formFields.forEach((field) => {
        field.addEventListener("focus", handleInteraction, { once: true });
        field.addEventListener("click", handleInteraction, { once: true });
      });

      const removeEventListeners = () => {
        formFields.forEach((field) => {
          field.removeEventListener("focus", handleInteraction);
          field.removeEventListener("click", handleInteraction);
        });
      };

      return removeEventListeners;
    }
  }, [formStarted, gtag]);

  const watchedFields = watch();
  useEffect(() => {
    const completedFields = Object.keys(watchedFields).filter(
      (field) => watchedFields[field],
    );
    completedFieldsRef.current = completedFields;
  }, [watchedFields]);

  useEffect(() => {
    const handleUnload = () => {
      if (gtag && !isFormSubmitted) {
        const endTime = Date.now();
        const duration = (endTime - startTimeRef.current) / 1000;

        gtag("event", "form_dropoff", {
          event_category: "988 Lifeline Survey",
          event_label: "Pre-Chat Survey",
          duration: duration,
          completed_fields_count: completedFieldsRef.current.length,
          completed_fields_list: completedFieldsRef.current.join(", "),
        });
      }
    };
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [gtag, isFormSubmitted]);

  function normalizeLanguage(language) {
    return language?.startsWith("en") ? "en" : language;
  }

  return (
    <>
      {platformServiceConfig ? (
        <GenesysPlatformService config={platformServiceConfig} />
      ) : (
        <Box>
          <Typography
            variant="h2"
            sx={{
              mt: 3,
              textAlign: "center",
            }}
          >
            {t("headerText.fillOutInfo")}{" "}
            <span style={{ fontWeight: "800" }}>
              {t("headerText.startChat")}
            </span>
          </Typography>
          <Typography
            variant="body1"
            sx={{ my: 1, mb: 3, textAlign: "center" }}
          >
            {t("headerText.veteranText")}{" "}
            <Link
              href={t("headerText.vclUrl")}
              underline="always"
              sx={{ color: theme.palette.primary.main }}
              target="_blank"
            >
              {t("headerText.here")}
            </Link>
          </Typography>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            {errors.root?.serverError && (
              <Box sx={{ mb: 3 }}>
                <Alert
                  severity="error"
                  sx={{
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: theme.palette.mediumRed,
                    bgcolor: theme.palette.lightRed,
                    color: "black",
                  }}
                >
                  <AlertTitle
                    sx={{
                      color: theme.palette.darkRed,
                      fontWeight: "bold",
                      fontSize: "1.25rem",
                    }}
                  >
                    {t("serverError.title")}
                  </AlertTitle>
                  {t("serverError.message")}
                </Alert>
              </Box>
            )}
            <Box ref={zipCodeRef}>
              <SurveyZipCode
                name="zipCode"
                control={control}
                register={register}
                errors={errors}
                spacingBottom={1}
              />
            </Box>
            <Box ref={thoughtsOfSuicideRef}>
              <SurveyQuestion
                errors={errors}
                question={t("suicideQuestion.title")}
                options={thoughtsOfSuicideChoices}
                name="thoughtsOfSuicide"
                control={control}
                required
                spacingTop={1.5}
                spacingBottom={1.5}
              />
            </Box>
            <Box ref={upsetAmountRef}>
              <SurveyQuestion
                errors={errors}
                question={t("upsetQuestion.title")}
                options={upsetScaleChoices}
                name="upsetAmount"
                control={control}
                required
                spacingTop={1.5}
                spacingBottom={1.5}
              />
            </Box>
            <FormControlLabel
              sx={{ mt: 1.5, mb: 3, display: "flex", alignItems: "flex-start" }}
              control={
                <Checkbox
                  {...register("isLGBTQ")}
                  sx={{
                    color: theme.palette.secondary.main,
                    "&.Mui-checked": {
                      color: theme.palette.primary.light,
                    },
                    pt: ".2rem",
                  }}
                />
              }
              label={
                <Typography
                  variant="h4"
                  sx={{
                    color: theme.palette.secondary.main,
                    fontSize: isDesktop ? "20px" : "16px",
                  }}
                >
                  {t("lgbtqQuestion.lgbtqText")}
                </Typography>
              }
            />
            <SurveyAccordion errors={errors} control={control} />
            <SurveyPhoneNumberQuestion
              control={control}
              name="phoneNumber"
              errors={errors}
            />
            <Button
              type="submit"
              variant="contained"
              disableElevation={true}
              disabled={isSubmitting}
              sx={{
                mt: 3,
                mb: 2,
                width: isEnglish ? 200 : 250,
                height: 60,
                borderRadius: 50,
                display: "flex",
                gap: 1,
                alignItems: "center",
                mx: "auto",
                fontFamily: "Public Sans",
                fontWeight: "bold",
                fontSize: 20,
                bgcolor: theme.palette.primary.main,
                textTransform: "none",
                color: "white",
              }}
            >
              {isSubmitting ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>
                  <ChatDialogueIcon />
                  {t("startChat.chatButtonText")}
                </>
              )}
            </Button>
            {retryCount > 0 && (
              <Typography variant="body2" sx={{ textAlign: "center", my: 2 }}>
                {t("submission.retryMessage", { retryCount })}
              </Typography>
            )}
            <Typography variant="body1" sx={{ textAlign: "center", mb: 1.5 }}>
              {t("startChat.termsOfServiceText")}{" "}
              <Link
                href={replaceMainSiteLinkHostname(
                  t("startChat.termsOfServiceUrl"),
                )}
                underline="always"
                sx={{ color: theme.palette.primary.main }}
                target="_blank"
              >
                {t("startChat.termsOfServiceLink")}
              </Link>
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "center", mb: 7 }}>
              <Link
                href={replaceMainSiteLinkHostname(
                  t("startChat.whatToExpectUrl"),
                )}
                underline="always"
                sx={{ color: theme.palette.primary.main }}
                target="_blank"
                rel="noopener"
              >
                {t("startChat.whatToExpectLink")}{" "}
              </Link>
              {t("startChat.whatToExpectText")}
            </Typography>
          </form>
        </Box>
      )}
    </>
  );
};

SurveyForm.propTypes = {
  interactionIdKey: PropTypes.string,
  filteredParams: PropTypes.object,
  gtag: PropTypes.func,
};

export default SurveyForm;
